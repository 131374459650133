.btn-hint, .btn-hint:hover {
    min-width: unset;
    min-height: unset;
    width: 14px;
    height: 14px;
    background: transparent;
    border: none;
    color: $white;
    @include font-size(14);
    @include font-weight("bold");
    cursor: pointer;
    padding: 0;

    i {
        &.default {
            color: map-get($dark, "black");
        }

        &.danger {
            color: map-get($red, 500);
        }

        margin: 0;
    }
}


.tooltip-default{
background-color: map-get($dark, "black");
    color: $white;
}

.tooltip-danger {
    background-color: map-get($red, 500);
    color: $white;
}