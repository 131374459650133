// Strips units from numbers
@function strip-unit($num) {
  @return math.div($num, ($num * 0 + 1));
}

// Uses one font-size declaration to create REM with PX fallback
@mixin font-size($font-size) {
  font-size: $font-size + px;
  font-size: math.div($font-size, $base-font-size) + rem;
}

// Uses font semantics for weights
@mixin font-weight($weight) {
  font-weight: map-get($weights, $weight);
}

// Creates responsive fontsizes
@mixin font-responsive($mobile, $tablet, $desktop) {
  @include font-size($mobile);
  @include mq-min-width(576) {
    @include font-size($tablet);
  }
  @include mq-min-width(960) {
    @include font-size($desktop);
  }
}

// Uses normal tracking as letter-spacing
@mixin letter-spacing($tracking) {
  letter-spacing: math.div($tracking, 1000) + rem;
}

// Vertical rhythm
@mixin font-baseline($font-size, $line-height) {
  @include font-size($font-size);
  line-height: math.div($line-height, $base-font-size) + rem;
  margin-top: $base-line-height + rem;
}

// Mix Colors
@function tint($color1, $color2, $percentage) {
  @return mix($color1, $color2, $percentage);
}

// Used to set attributes below a specified breakpoint
@mixin mq-max-width( $width-in-px ) {
  $width: strip-unit($width-in-px);
  @media only screen and (max-width: (math.div($width, $base-font-size) + rem)) {
    @content;
  }
}

// Used to set attributes above a specified breakpoint
@mixin mq-min-width( $width-in-px ) {
  $width: strip-unit($width-in-px);
  @media only screen and (min-width: (math.div($width, $base-font-size) + rem)) {
    @content;
  }
}

// Used to set attributes between specified breakpoints
@mixin mq-min-max-width( $min-width-in-px, $max-width-in-px ) {
  $min-width: strip-unit($min-width-in-px);
  $max-width: strip-unit($max-width-in-px);
  @media only screen and (min-width: (math.div($min-width, $base-font-size) + rem)) and (max-width: (math.div($max-width, $base-font-size) + rem)) {
    @content;
  }
}

// Reverse outline to solid buttons
@mixin button-reverse-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: white;
  background-color: $color;
  background-image: none;
  border-color: $color;
  &:hover, &:focus {
    color: $color-hover;
    background-color: transparent;
    border-color: $active-border;
  }
}

@mixin responsive-padding($top,$right,$bottom,$left) {
  padding: math.div($top, $base-font-size) + rem math.div($right, $base-font-size) + rem math.div($bottom, $base-font-size) + rem math.div($left, $base-font-size) + rem;
}

@mixin responsive-margin($top,$right,$bottom,$left) {
  margin: math.div($top, $base-font-size) + rem math.div($right, $base-font-size) + rem math.div($bottom, $base-font-size) + rem math.div($left, $base-font-size) + rem;
}

@mixin text-primary() {

}

@mixin collapse-transition() {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

// svg background mask for the fontawesome times icon
// we might want to move this, and future iterations (other icons) into it's own mixins file. or find a way to make this extensible for multiple icons/sizes.
@mixin fas-times() {
  background-color: $white;

  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path stroke='white' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/></svg>");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 9px;
  -webkit-mask-position: center;

  mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path stroke='white' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/></svg>");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 9px;
}

@mixin fa-icon() {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin fa-thin() {
  @include fa-icon();
  font-weight: 100;
  font-family: "Font Awesome 6 Pro";
}