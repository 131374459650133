@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;

}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: fallback;
}
