table {
  color: map-get($typography, "primary");
  border: 2px solid map-get($gray, 700) !important;
  background-color: map-get($gray, 200);
  border-radius: .25rem;

  thead {
    background-color: map-get($gray, 200);
    border: none;

    tr {
      th {
        border-left: 2px solid map-get($gray, 700);
        border-right: 2px solid map-get($gray, 700);
        border-bottom: 2px solid map-get($gray, 700) !important;
        @include font-size(18);
        @include font-weight("bold");
      }

      th:first-of-type {
        border-left: none;
      }

      th:last-of-type {
        border-right: none;
      }

      th:not(first-of-type):not(last-of-type) {
        border-right: none;
      }
    }
  }

  tbody {
    background-color: map-get($background-colors, "white");
    border-top: none;

    tr {

      td {
        border-bottom: 2px solid map-get($gray, 700);
        @include font-size(16);
        padding: .25rem;

        a {
          color: map-get($blue, 400);
        }

        a:hover {
          color: map-get($blue, 700);
        }
      }
    }
  }

  tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.table td {
  vertical-align: middle;
}

table.dataTable thead .sorting {
  background-image: none !important;
}

.dataTables_length select {
  padding-right: 2rem !important;
}

.table-header {
  @include font-size(15);
  font-weight: 600;
  display: flex;
  border-bottom: none;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  padding: 10px 15px;
}

.table-header-buttons {
  margin: 0 0 0 auto;

  .btn {
    @include font-size(11);
    padding: 2px 7px;
    margin-top: 1px;
  }
}

@each $color, $value in $cs-current {
  .table-colored-#{$color} {
    .table-header {
      background: darken($value, 5%);
      color: $white;
    }
  }
}

@each $color, $value in $bs-colors {
  .table-colored-#{$color} {
    .table-header {
      background: darken($value, 5%);
      color: $white;
    }
  }
}

/*Gijgo FontAwesome Overrides */
.fa-sort-amount-asc:after {
  content: '\f885';
}

.fa-sort-amount-desc:after {
  content: '\f160';
}