/* loader spinner */
.spinner.loading {
  display: inline-block;
  border: solid;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

span.field-validation-error {
  display: inline-block;
  margin-top: 10px;
  margin-left: 3px;
}