// FontAwesome
.fa-rotate-225 {
  transform:rotate(225deg);
}
.fa-rotate-315 {
  transform:rotate(315deg);
}

@for $i from 1 through 10 {
  .opacity-#{$i} {
    opacity:math.div($i, 10);
  }
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}