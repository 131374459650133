input[type=checkbox] {
  width: 1.25rem;
  color: $white;
  border: 1px solid map-get($gray, 700);
  height: 1.25rem;
  opacity: 1;
  border-radius: .25rem;
}

input[type=checkbox]:checked {
  accent-color: map-get($default-green, 700);
}

input[type=checkbox]:disabled {
  accent-color: map-get($gray, 400);
  background-color: map-get($gray, 400);
}

input[type=checkbox]:disabled.important {
  accent-color: map-get($red, 300);
  background-color: map-get($red, 300);
}

// If the below doesn't work - take a look here: https://alvarotrigo.com/blog/toggle-switch-css/
// #3 looks very much like what we would need
// Initial variables and sizing for the slider
$sliderSize: 1.125em;
$sliderPadding: .125em;
$sliderTransitionTime: .3s;
$borderRadius: $sliderSize;

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  // The slider width * 3, because the slider transitions twice its width, plus the padding on left and right, plus the border left and right.
  width: $sliderSize * 2 + $sliderPadding * 2 + .125em;
  // The slider height + padding bottom and top + the height of the border on the bottom and top.
  height: $sliderSize + 2 * $sliderPadding + .125em;
}

.switch.form-control {
  border: none !important;
}

label.switch.form-control {
  margin-bottom: 0px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  -webkit-transition: $sliderTransitionTime;
  transition: $sliderTransitionTime;
}

input:checked + .slider {
  background-color: $white;
}

.slider:before {
  position: absolute;
  content: "";
  height: $sliderSize;
  width: $sliderSize;
  left: $sliderPadding;
  bottom: $sliderPadding;
  background-color: map-get($gray, 700);
  -webkit-transition: $sliderTransitionTime;
  transition: $sliderTransitionTime;
}

.slider-important:before {
  position: absolute;
  content: "";
  height: $sliderSize;
  width: $sliderSize;
  left: $sliderPadding;
  bottom: $sliderPadding;
  background-color: map-get($red, 500);
  -webkit-transition: $sliderTransitionTime;
  transition: $sliderTransitionTime;
}

.slider.round {
  border-radius: $borderRadius;
  border: .0625em solid map-get($gray, 700);
}

.slider-important.round {
  border-radius: $borderRadius;
  border: .0625em solid map-get($red, 500);
}

input:checked + .slider:before {
  -webkit-transform: translateX($sliderSize);
  -ms-transform: translateX($sliderSize);
  transform: translateX($sliderSize);
  background-color: map-get($success-green, 500);

}

input:checked + .slider.round {
  border: .0625em solid map-get($success-green, 500);
}

/* Addeds border-radius to the slider when .rounded class is added */
.slider.round:before {
  border-radius: $borderRadius;
}

input + .display {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  padding: 0;
  pointer-events: none;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: $input-bg;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: $main-accent;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid $main-accent;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $main-accent;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:disabled {
  color: map-get($gray, 400);
  border-color: map-get($gray, 400);
  
  + span {
    color: map-get($gray, 400);
  }
}

// Remove the counters from numeric fields:
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
