.login {
  height: 100%;
  min-height: 100vh;

  .row {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  background: $primary;
  background: linear-gradient(320deg, rgba(map-get($dark, "black"), 1) 0%, rgba($primary, 1) 35%, rgba(map-get($light, "white"), 1) 100%);

  .pricing {
    width: auto;

    th, td {
      padding: .5rem;
    }
  }
}
