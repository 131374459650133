// Border overrides that will overwrite any other border applied to an element or class
.border-default-none {
  border: none !important;
}

.border-default-top-none {
  border-top: none !important;
}

.border-default-right-none {
  border-right: none !important;
}

.border-default-bottom-none {
  border-bottom: none !important;
}

.border-default-left-none {
  border-left: none !important;
}

.border {
  border: $border-default !important;
}

.border-default-top {
  border-top: $border-default !important;
}

.border-default-right {
  border-right: $border-default !important;
}

.border-default-bottom {
  border-bottom: $border-default !important;
}

.border-default-left {
  border-left: $border-default !important;
}

// Border overrides that will overwrite any other border applied to an element or class
.border-default-radius-none {
  border-radius: 0px !important;
}

.border-default-radius-top-left-none {
  border-top-left-radius: 0px !important;
}

.border-default-radius-top-right-none {
  border-top-right-radius: 0px !important;
}

.border-default-radius-bottom-left-none {
  border-bottom-left-radius: 0px !important;
}

.border-default-radius-bottom-right-none {
  border-bottom-right-radius: 0px !important;
}

.border-default-radius {
  border-radius: .25rem !important;
}

.border-default-radius-top-left {
  border-top-left-radius: .25rem !important;
}

.border-default-radius-top-right {
  border-top-right-radius: .25rem !important;
}

.border-default-radius-bottom-right {
  border-bottom-right-radius: .25rem !important;
}

.border-default-radius-bottom-left {
  border-top-left-radius: .25rem !important;
}

.box-shadow-bottom {
  box-shadow: 0 4px 2px -2px map-get($gray, 500);
}

.border-form-control-gray {
    border-color: $form-control-gray !important;
    border-style: solid;
}

@for $i from 1 through 5 {
  .border-#{$i} {
    border-width: #{$i}px;
  }
}