.nav.nav-tabs {
  border: none;

  .nav-link {
    @include font-weight("bold");
    background: $white;
    border: $border-default;
    margin-bottom: -2px;
    color: map-get($gray, 900);
    border-radius: 0px;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    + .nav-link {
      // margin-left: .25rem;
    }

    &:hover {
      color: $gray-900;
      background: map-get($gray, 400);
    }

    &.active {
      background: map-get($default-green, 700);
      color: $white;
      border-color: map-get($default-green, 700);
      border: $border-default;

      .badge {
        background-color: darken($main-accent, 20%);
      }
    }

    .badge {
      @include font-size(11);
      margin-left: 7px;
      padding: 3px 5px;
    }
  }

  .nav-link:first-of-type {
    border-radius: .25rem 0 0 0;
    border-left: $border-default;

    &.active {
      border-left: $border-default;
    }
  }

  .nav-link:last-of-type {
    border-radius: 0px .25rem 0 0;
    border-right: 2px solid map-get($gray, 700);

    &.active {
      border-right: 2px solid map-get($default-green, 700);
    }
  }

  .nav-link:not(:first-of-type):not(:last-of-type) {
    &.active {
      border-left: none;
      border-right: $border-default;
    }
  }

  .nav-link:not(:first-of-type):not(:last-of-type):not(:active) {
    border-left: none;
  }


  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      &.tabs#{$infix} {
        flex-direction: column;

        .nav-link {
          &:not(:first-child) {
            border-radius: 0;
          }

          + .nav-link {
            margin-left: 0;
          }
        }
      }
    }
  }


  .tab-content {
    .tab-pane {
      .card {
        border-top-left-radius: 0px;
      }
    }

    padding: 15px;
  }

  .tab-content-bar {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: -2px;
      left: -1px;
      right: 0;
      height: 5px;
      width: calc(100% + 2px);
    }

    @each $color, $value in $bs-colors {
      &.bar-#{$color}:before {
        background: $value;
      }
    }
  }
}
