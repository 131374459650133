.metafarms-content {
  min-width: 500px; // todo: this prevents things from squishing too small, but this definitely isn't responsive friendly.
  height: 100vh;
  position: relative;
  //top: 70px;

  .breadcrumb {
    margin: -20px -20px 20px;
    padding: 6px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .07);
    @include font-size(12);
    background: transparent;

    li {
      display: inline-block;

      + li:before {
        color: #ccc;
        content: '/ ';
        padding: 0 5px 0 8px;
      }
    }

    .active {
      color: #444;
    }

    a {
      color: #999;
    }
  }
}

hr.page-wide-block {
  border-color: #e6e6e6;
  margin: 20px -20px;
  height: 0;
}
