$primaryNavBackground: map-get($gray, 200);
$headerBackground: map-get($gray, 200);

$menuIconContainerSize: 40;
$menuIconContainerSizeWithUnits: $menuIconContainerSize + px;
$menuIconCollapseSize: 18;
$menuIconCollapseSizeWithUnits: $menuIconCollapseSize + px;
$navItemBorderWidth: 6px;
$activeNavItemColor: map-get($default-green, 700);
$activeBorderStroke: solid $navItemBorderWidth;
$collapseActiveBorderStroke: solid 4px;
$expandedWidth: 15.5rem;
$collapseWidth: 4.25rem;
$menuItemHeight: 2.6rem;
$borderThickness: 2px;
$borderStroke: solid map-get($gray, 700) $borderThickness;
$navItemTextColor: map-get($gray, 800);

$headerHeight: 4rem;
$logoHeight: 4rem;
$logoWidth: 15rem;
$logoCollapseSize: 2.75rem;

$headerIconSize: 2.5rem;
$zindex-nav: 1000 !default;

$contentPadding: 1.5rem;

@mixin nav-icon() {
  @include font-size(25);
  width: calc($menuIconCollapseSizeWithUnits + 8px);
  height: $menuIconCollapseSizeWithUnits;
  display: inline-block;
  margin-right: 0.5rem;
}

// primary navigation bar, handling both expanded & collapsed states
//
.navbar-primary {
  font-size: 1rem;
  background-color: $primaryNavBackground;
  position: absolute;
  width: $expandedWidth;
  top: $headerHeight;
  left: 0;
  bottom: 0;
  z-index: $zindex-nav;
  @include collapse-transition();

  @include font-size(16);
  @include font-weight('bold');

  padding: 0; // override the default padding of .navbar  

  .navbar-primary-inner {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    border-right: $borderStroke;
  }

  .nav-item {
    //height: 3rem;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: transparent !important;
    @include font-size(16);
    @include font-weight('bold');

    a.nav-link {
      color: $navItemTextColor;
      height: inherit;
      width: inherit;

      &.nav-link-primary {
        height: 3rem; // only set the height of the link/toggle. otherwise the expanded items will not shift the rest of the menu down.
        padding: 1.75rem;
        display: flex;
        align-items: start;

        &::after {
          margin-top: 0.5rem;
        }
      }
    }
  }

  a.nav-link {
    color: $navItemTextColor;
    height: inherit;
    width: inherit;

    .nav-icon {
      @include nav-icon();
    }

    .nav-label {
      flex-grow: 1;
    }

    &:hover, &.active, &.dropdown-toggle.show {
      color: $activeNavItemColor !important;
    }
  }

  .dropdown-menu {
    background-color: $white;
    border-radius: 0;
    margin-top: 10px;

    // secondary menu items
    .nav-link {
      padding: 0.5rem 1.75rem;

      &.active, &:active {
        color: $white !important;
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      position: static; // this will make the dropdown display inline shifting the content down.
    }
  }

  .dropdown.dropend {
    .dropdown-menu {
      position: absolute; // this will make the dropdown appear inline with the nav item, mimicking an accordion type display
    }
  }

  .navbar-primary-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: solid 1px #666;

    display: flex;
    flex-direction: row;

    .nav-item {
      flex-grow: 1;
      //margin: 1rem 0;

      .nav-link {
        height: auto !important;
        padding: 1rem 1.75rem;
      }
    }

    .btn-expand-collapse {
      padding: 0.5rem;
      border: none;

      color: grey;
      font-size: 20px;
      text-align: center;
      background-color: transparent;

      &:hover, &:focus {
        color: $activeNavItemColor;
      }

      i::after {
        content: "\f323"; // double chevron left
      }
    }
  }

  &.menu-collapsed {
    width: $collapseWidth;

    .nav-icon {
      position: relative;
      left: 0.5rem;
    }

    .nav-link-primary {
      padding: 0.75rem !important;

      &::after {
        // fix some positioning issues with padding and etc.
        position: relative;
        right: -0.3rem;
        top: 0;
      }

      .nav-label {
        display: none !important;
      }
    }

    .navbar-primary-footer {
      flex-direction: column;
    }

    .btn-expand-collapse {
      i::after {
        content: "\f324"; // double chevron right
      }
    }

    // force the dropdown menu to be hidden while prerendering & the menu is collapsed
    // this is to prevent the dropdown menu from being visible while the page is loading from pre-render to interactive.
    &.prerendered {
      .dropdown-menu {
        display: none !important;
      }
    }
  }
}

// this is in the _nav partial, because it's positioning is based on the primary nav bar positioning.
//
#page-header {
  position: relative;
  width: 100%;
  //top: -2px; // todo: this is temporary, needs positioning and sizing adjustments. this is the fake it until you make it approach.
  left: 0;
  display: flex;
  align-items: center;
  height: $headerHeight;
  background-color: $headerBackground;
  border-bottom: $borderStroke;

  // column 1
  .logo-container {
    flex: 0 0 $expandedWidth; /* fixed with to menu width */
    height: inherit;
    cursor: pointer;
    padding: 0;
    border-right: $borderStroke;
    background-color: map-get($default-green, 700);

    @include collapse-transition();

    background: {
      image: url(/images/MetaFarmsLive_Logo_White_with_Text.png);
      size: $logoWidth $logoHeight;
      repeat: no-repeat;
      position-y: calc(($headerHeight - $logoHeight) / 2);
      position-x: calc(($expandedWidth - $logoWidth) / 2);
    }

    &.menu-collapsed {
      flex: 0 0 $collapseWidth; /* fixed with to menu width */

      position: relative;
      background: {
        image: url(/images/MetaFarms_Logo.svg);
        size: $logoCollapseSize $logoCollapseSize;
        position-y: calc(($collapseWidth - $logoCollapseSize) / 2);
        position-x: calc(($collapseWidth - $logoCollapseSize) / 2);
      }
    }
  }

  // column2 
  .header-title {
    padding: $contentPadding;
    flex: 1; /* Grow to fill remaining space */
    display: flex;

    > * {
      margin: 0 0.5rem;
    }

    .page-header-icon {
      font-size: 40px;
      color: $black;
      width: $headerIconSize;
      height: $headerIconSize;
      position: relative;
      display: block;
      top: 0.25rem;
    }

    .header-title-text {
      display: block;

      a {
        color: $link-color;
        text-decoration: underline;
      }
    }
  }

  // column 3
  #company-selector {
    flex: 0 0 auto; /* Width based on content */
    margin-right: $contentPadding
  }
}

// top nav bar for tertiary menu items...these tertiary nav bar uses nav/tabs pills. 
// these styles mostly just customize how those pills are displayed.
//
.navbar-tertiary {
  margin-left: $expandedWidth;
  padding: 0 $contentPadding;
  height: 2.5rem;
  border-bottom: $borderStroke;

  .nav-item {
    background-color: transparent;
    padding: .5rem .75rem 0 .5rem;

    .nav-link {
      padding: 0 0.75rem;
      background-color: transparent;
      color: map-get($typography, 'primary');

      border-radius: 0;
      border-bottom: $activeBorderStroke $white;

      &.active {
        border-bottom-color: $activeNavItemColor;
      }
    }

    &:hover {
      .nav-link {
        border-bottom-color: map-get($gray, 200);
      }
    }
  }

}

/**
  Main Content
 */
.main-content {
  padding: $contentPadding;
  margin-left: $expandedWidth;
  overflow: scroll; // ensure content scrolls, and the menu + header is sticky
  height: 100vh; // ensure content is always 100% of the viewport height
}

.menu-collapsed ~ .main-content,
  // also tertiary menu items
.menu-collapsed ~ .navbar-tertiary {
  margin-left: $collapseWidth;
}
