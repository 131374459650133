
.btn {
  @include font-size(16);
  min-width: 6.375em;
  @include font-weight("bold");
  @include letter-spacing(8);
  text-transform: uppercase;
  border-radius: .25rem;
  white-space: nowrap;
  border: 1px solid;

  &:after {
    clear: both;
  }

  i {
    @include font-baseline(16, 16);
  }
  
  &:disabled {
    cursor: not-allowed;
  }
}

.btn-icon{
    min-width: auto;
}

.btn i{
    margin-top: 0;
}

a.btn {
  padding: 0;
  line-height: 2.25;
}

.btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active, .btn:active {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

.btn:focus {
  outline: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

// button sizes
.btn-sm {
  padding: .125rem .4375rem;
  @include font-size(11);
}

.btn-lg {
  padding: .5rem 1rem;
  @include font-size(16);
}

// buttons with icons
[class*='btn-icon'] {
  &[class*='-left'] {
    i {
      margin-right: 6px;
    }

    &[class*='-split'] {
      padding-left: 0;

      i {
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        margin: -4px 12px -4px 0;
      }
    }
  }

  &[class*='-right'] {
    i {
      margin-left: 10px;
      float: right;
    }

    &[class*='-split'] {
      padding-right: 0;

      i {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        margin: -4px 0 -4px 12px;
      }
    }
  }

  &[class*='-split'] i {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.07);
    padding: 4px 12px;
  }
}

// button iterations for each cs-current color
@each $color, $value in $cs-current {
  .btn-#{$color} {
    @include button-variant($value, darken($value, 5%));
  }
}

// outline button iterations for each cs-current color
@each $color, $value in $cs-current {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// reverse outline button iterations for each cs-current color
@each $color, $value in $cs-current {
  .btn-reverse-outline-#{$color} {
    @include button-reverse-outline-variant($value, $value);
  }
}

// button iterations for each bs-colors color
@each $color, $value in $bs-colors {
  .btn-#{$color} {
    @include button-variant($value, darken($value, 5%));
  }
}

// outline button iterations for each bs-colors color
@each $color, $value in $bs-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// reverse outline button iterations for each bs-colors color
@each $color, $value in $bs-colors {
  .btn-reverse-outline-#{$color} {
    @include button-reverse-outline-variant($value, $value);
  }
}

// button iterations for each grayscale color
@each $color, $value in $gray {
  .btn-#{$color} {
    @include button-variant($value, darken($value, 5%));
  }
}

// outline button iterations for each bs-colors color
@each $color, $value in $gray {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// reverse outline button iterations for each bs-colors color
@each $color, $value in $gray {
  .btn-reverse-outline-#{$color} {
    @include button-reverse-outline-variant($value, $value);
  }
}

// Standard Buttons
@each $color1, $scale in $color-scales {
  @each $color2, $value in $scale {
    @if ($color2 >= 100 and $color2 <= 700) {
      .btn-#{$color1}-#{$color2} {
        background: $value !important;
        border: 1px solid $value !important;

        @if ($color2 >= 400) {
          color: map-get($typography, 'light') !important;
        }
        @else {
          color: map-get($typography, 'primary') !important;
        }
      }

      .btn-#{$color1}-#{$color2}:hover {
        background: map-get($scale, $color2 + 100) !important;
        border: 1px solid map-get($scale, $color2 + 100) !important;

        @if ($color2 >= 400) {
          color: map-get($typography, 'light') !important;
        }
        @else {
          color: map-get($typography, 'primary') !important;
        }
      }

      .btn-#{$color1}-#{$color2}:active {
        background: map-get($scale, $color2 + 200) !important;
        border: 1px solid map-get($scale, $color2) !important;

        @if ($color2 >= 400) {
          color: map-get($typography, 'light') !important;
        }
        @else {
          color: map-get($typography, 'primary') !important;
        }
      }

      .btn-#{$color1}-#{$color2}:disabled {
        background: $value !important;
        border: 1px solid $value !important;

        @if ($color2 >= 400) {
          color: map-get($typography, 'light') !important;
        }
        @else {
          color: map-get($typography, 'default') !important;
        }
      }
    }
  }
}

// Outline Buttons
@each $color1, $scale in $color-scales {
  @each $color2, $value in $scale {
    // Primary Green
    @if ($color2 == 700 and $scale == $default-green) {
      .btn-outline-#{$color1}-#{$color2} {
        background: map-get($light, "white") !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:hover {
        background: map-get($scale, 50) !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:active {
        background: map-get($scale, 100) !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:disabled {
        background: map-get($light, "white") !important;
        border: 1px solid $value !important;
        color: $value !important;
      }
    }
    // Red Danger
    @if ($color2 == 500 and $scale == $red) {
      .btn-outline-#{$color1}-#{$color2} {
        background: map-get($light, "white") !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:hover {
        background: map-get($scale, 50) !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:active {
        background: map-get($scale, 100) !important;
        border: 1px solid $value !important;
        color: $value !important;
      }

      .btn-outline-#{$color1}-#{$color2}:disabled {
        background: map-get($light, "white") !important;
        border: 1px solid $value !important;
        color: $value !important;
      }
    }
  }
}

.btn-link {
  background: transparent;
  border: none;
  color: $link-color;
  // remove styles applied by other files
  text-transform: unset;  
  text-decoration: none;

  &:hover {
    color: darken($link-color, 20%);
  }

  &:disabled {
    color: lighten($link-color, 15%);
    cursor: not-allowed;
  }
}

.btn-group {  
  & i {
    margin-top: 0;
    margin-right: 0.3rem;
  }
  
  & .loading .spinner {
    margin-top: 0;
    margin-right: 0.3rem;
  }
}

// Used on append input button only.
.btn-clear-input-append {
    background: transparent;
    min-width: 2em;
    border-left: none;
    border-bottom: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
    border-top: 1px solid $input-border-color;
    color: inherit;
    border-radius: 0px 2px 2px 0px;
}

// Used on append input button only, we aren't currently setting the hover state for this button.
.btn-clear-input-append:hover {
    background: transparent;
    min-width: 2em;
    border-left: none;
    border-bottom: 1px solid $input-border-color;
    border-right: 1px solid $input-border-color;
    border-top: 1px solid $input-border-color;
    color: inherit;
    border-radius: 0px 2px 2px 0px;
}
