.subtitle {
  @include font-baseline(24, 32);
  @include font-weight("bold");
  @include responsive-padding(0, 0, 12, 0)
}

.text-regular {
  @include font-baseline(16, 24);
  @include font-weight("regular");
  @include responsive-padding(0, 0, 16, 0)
}

.text-bold {
  @include font-baseline(16, 24);
  @include font-weight("bold");
  @include responsive-padding(0, 0, 16, 0)
}

.text-gray {
    color: $form-control-gray !important;
}

@each $color, $value in $gray {
  .text-#{$color} {
    color: $value
  }
}

@each $color, $value in $typography {
  .text-#{$color} {
    color: $value !important;
  }
}


@each $color, $value in $primary-system-colors{
    .text-#{$color}{
        color: $value !important;
    }

    .icon-#{$color}{
        color: $value !important;
    }
}
    
    
