*,
*:before,
*:after {
  box-sizing: border-box;
}

$headerLetterSpacing: -2;


// Base Styles
//–––––––––––––––––––––––––––––––––––––––––––––––––

html {
  height: 100%;
}

body, html {
  min-height: 100%;
  width: 100%;
  overflow: visible;
}

body {
  @include font-size(13);
  font-family: $fs-main;
  font-weight: 400;
  line-height: $base-line-height;
  color: map-get($typography, "primary");
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  position: relative;
  height: auto;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $fs-header;
}

h1, .h1 {
  @include font-size(72);
  @include font-weight("bold");
}

h2, .h2 {
  @include font-size(48);
  @include font-weight("bold");
}

h3, .h3 {
  @include font-size(32);
  @include font-weight("bold");
}

h4, .h4 {
  @include font-size(24);
  @include font-weight("bold");
}

h5, .h5 {
  @include font-size(20);
  @include font-weight("bold");
}

h6, .h6 {
  @include font-size(18);
  @include font-weight("bold");
}

p {
  margin-bottom: $base-line-height + rem;
}

ul,
ol,
pre,
table,
blockquote {
  margin-bottom: 1.5rem;
}


// Sanitation
//–––––––––––––––––––––––––––––––––––––––––––––––––

ul ul,
ol ol,
ul ol,
ol ul {
  margin: 0;
}

b,
strong,
em,
small,
code {
  line-height: 1;
}

sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -.4em;
}

sub {
  top: .4em;
}


// Links
//–––––––––––––––––––––––––––––––––––––––––––––––––

a {
  color: $link-color;
  text-decoration: none;
  transition: .25s ease-in-out all;

  &:hover {
    color: darken($link-color, 20%);
    text-decoration: none;
  }
  
  &.disabled {
    color: lighten($link-color, 15%);
    cursor: not-allowed;
  }
}

header a, footer a {
  color: $main-shade;
  font-weight: 600;

  &:hover {
    color: lighten($main-shade, 20%);
  }
}

.pseudo-link {
  cursor: pointer;
}


// hr and pre
//–––––––––––––––––––––––––––––––––––––––––––––––––

hr {
  display: block;
  height: 1px;
  width: 25%;
  border: 0;
  border-top: 1px solid white;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0;
}

pre {
  background: $light-shade;
  padding: 10px 15px;
}