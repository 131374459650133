// bg classes from background-colors array
@each $color, $value in $background-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
}

// bg classes from scale arrays
@each $color1, $scale in $color-scales {
  @each $color2, $value in $scale {
    .bg-#{$color1}-#{$color2} {
      background-color: $value !important;

      @if $color2 >= 500 {
        color: map-get($light, "white");
      } @else {
        color: map-get($typography, "default");
      }
    }
  }
}

.bg-dark {
  background-color: map-get($dark, "black") !important;
  color: map-get($light, "white");
}

.bg-light {
  background-color: map-get($light, "white") !important;
  color: map-get($typography, "default");
}
