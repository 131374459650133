.form-switch input[type=checkbox]:not(:checked).important {
  background-image: url("../../images/Slider_Important.svg");
  border: 1px solid map-get($red, 500);
}

// .form-switch on wrapper div with input class="form-check-input important" with focus applied
.form-switch input[type=checkbox]:not(:checked).important:focus {
  background-image: url("../../images/Slider_Important.svg");
  box-shadow: 0 0 0 0.25rem rgb(196 42 23 / 25%);
}

.form-switch .form-check-input{
    width: 3em;
}

.form-switch .form-check-input.small {
  width: 2.5em;
}

.form-switch .form-check-input.large {
  width: 4em;
}

