.card {
  color: map-get($typography, "primary");
  background-color: map-get($light, white);
  border: none;

  p:last-child {
    margin-bottom: 0;
  }

  .card-header {
    min-height: 4rem;
    padding: 9px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: .25rem .25rem 0 0;
    border: $border-default;
    background-color: map-get($gray, 200);

    .card-title {
      margin-bottom: 0rem;
    }
  }

  .card-body {
    background-color: map-get($gray, 200);
    border-top: none;
    border-bottom: $border-default;
    border-left: $border-default;
    border-right: $border-default;
    border-radius: 0 0 .25rem .25rem;
  }

  .card-body-light {
    background-color: $white;
  }

  // custom classes to set classes on card body so that the height of the card will stick to the bottom of the content area and scroll any content overflow.
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {

      .card-body.card-body-sticky-vh-#{$breakpoint} {
        max-height: calc(100vh - var(--bs-card-spacer-y) - var(--card-body-offset)); // offset variable is calculated by /Shared/Cards/CardBody.razor.js
        overflow-y: scroll;
      }

    }
  }

  .card-footer {
    background-color: map-get($gray, 200);
  }

  &.loading {
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      left: -1px;
      background: $black;
      opacity: .5;
      z-index: 1;
    }

    &:after {
      content: '\f110';
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 16px);
      font-family: $fs-icon-normal;
      font-weight: 900;
      animation: fa-spin 1s steps(8) infinite;
      color: white;
      @include font-size(32);
      user-select: none;
      z-index: 2;
    }
  }

  .card-header-controls {
    margin: .25rem 0;
    /* Unused Small Buttons
    .btn {
        @extend .btn-sm;
        margin-top:1px;
    }
    */
  }
}

.tab-pane .card {
  border-top-left-radius: 0px;
}

.card-full-screen {
  bottom: 0;
  overflow-y: scroll;
}

.card-transparent {
  border: none !important;
  background: none !important;

  > .card-header {
    background: none !important;
  }

  > .card-body {
    background: none !important;
  }

  > .card-footer {
    border: none !important;
    background: none !important;
  }
}

// border radius overloads for cards for one offs that don't fit hierarchy:
.card-border-radius-top-left-none {
  border-top-left-radius: 0px;
}

.card-border-radius-top-right-none {
  border-top-left-radius: 0px;
}

.card-border-radius-bottom-right-none {
  border-bottom-left-radius: 0px;
}

.card-border-radius-bottom-right-none {
  border-top-left-radius: 0px;
}

.card-border-radius-top-left {
  border-top-left-radius: .25rem;
}

.card-border-radius-top-right {
  border-top-left-radius: .25rem;
}

.card-border-radius-bottom-right {
  border-bottom-left-radius: .25rem;
}

.card-border-radius-bottom-right {
  border-top-left-radius: .25rem;
}
