// Data grid custom component/directive
//
.dataGrid_wrapper {
  position: relative;
  clear: both;
  display: flex;
  flex-flow: column;

  // min sizing of the grid so controls don't wrap and squish oddly
  min-width: 22rem;

  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }

  // this is a class applied to the dataGrid_wrapper by grid-interop.js when the size of the grid wrapper
  // is outside the bounds of the main content area. this allows min height to be set on the grid for overflow purposes
  &.container-overflow {
    min-height: 18rem;
  }

  th {
    .dataGrid_sort_icon {
      float: right;
    }

    // blazor implementation has a nested element for this class
    &.dataGrid_column_sortable, .dataGrid_column_sortable {
      cursor: pointer;
    }
  }

  .dataGrid_loading {
    background-color: map-get($gray, 200);
  }

  .dataGrid_paginate {
    //float: right;
    padding-top: .25em;
    margin: 0;
    white-space: nowrap;
    text-align: right;

    ul.pagination {
      margin: 2px 0;
      white-space: nowrap;
      justify-content: flex-end;
      display: flex;
      padding-left: 0;
      list-style: none !important;
      border-radius: .25rem;

      .paginate_button {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
        padding: 0;
        margin-left: 0;
        //padding: .5em 1em;
        //margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        color: #333 !important;
        border: 0 solid transparent;
        border-radius: 0;
      }
    }
  }  

  .dataGrid_filter {
    flex: 0 1 auto;

    text-align: right;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    padding-bottom: 10px;
    height: 49px; // todo: does this need to be rem?

    .dataGrid_filter_controls {
      flex-basis: 100%;
      justify-content: flex-start;
      display: flex;
      flex-flow: row;
    }

    .dataGrid_filter_chips {
      display: flex;
      justify-content: flex-start;
      flex-basis: 100%;
      padding: 0 10px;
      text-align: center;
      line-height: 60px;
      @include font-size(16);

      p {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
        margin: 7px 5px;
      }
    }

    .dataGrid_filter_search {

      label {
        float: right;
        font-weight: normal;
        white-space: nowrap;
        text-align: left;
      }

      input {
        margin-left: .5em;
        display: inline-block;
        width: auto;
      }
    }
  }

  .dataGrid_content {
    flex: 1 1 auto;
  }

  .dataGrid_meta {
    flex: 0 1 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
    flex-wrap: wrap;

    .dataGrid_meta_item {
      //padding-top: 10px;
      //padding-bottom: 10px;
    }

    .dataGrid_meta_limiter {
      width: 220px;
    }

    .dataGrid_meta_status {
      margin: auto;
      text-align: center;
    }
  }
}

// style override the quickgrid component
.quickgrid {
  button.col-title {
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 0;

    width: 100%;

    // doesn't pick up table default header styles
    @include font-size(18);
    @include font-weight("bold");

    // flex box layout
    display: flex !important; // d-flex
    justify-content: space-between !important; // justify-content-between 
    align-items: center !important; // align-items-center

    .col-title-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      margin-right: 1rem !important; // me-1
    }
  }

  .sort-indicator {
    @include fa-thin();

    color: map-get($gray, 400); // text-400

    &:before {
      content: "\f0dc"; // fa-sort
    }

  }

  .col-sort-desc {
    .sort-indicator {
      &:before {
        content: "\f0de"; // fa-sort-up
      }
    }
  }

  .col-sort-asc {
    .sort-indicator {
      &:before {
        content: "\f0dd"; // fa-sort-down
      }
    }
  }

  &.loading {
    tr {
      background-color: map-get($gray, 200);
    }
  }
}