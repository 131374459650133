// Select
select {
  &.form-control {
    background: $white;
    border: 2px solid map-get($gray, 700);
    border-radius: .25rem;
  }
}

select {
  &.form-control:active, &.form-control:focus, &.form-control:focus-within {
    outline: none;
    border: $border-default;
  }
}

select {
  background: $white;
  border: $border-default;
  border-radius: .25rem;
}

option {
  @include font-weight("regular");
  padding-left: .5rem;
  padding-right: 1.5rem;
  color: map-get($gray, 900);
  font-size: 1rem;
}

label, optgroup {
  @include font-weight("regular");
  font-size: 1rem;
}

option:hover {
  background-color: map-get($gray, 200);
}

option:checked {
  background: linear-gradient(map-get($default-green, 700), map-get($default-green, 700));
  background-color: map-get($default-green, 700);
  color: $white;
  -webkit-text-fill-color: $white;
}

select:active, select:focus, select:focus-within {
  outline: none;
  border: $border-default;
}