﻿.toast-wrapper {
  &.position-absolute {
    top: 20px;
    right: 23px;
  }
}

.toast {
  border: $border-default;
  border-radius: $border-radius-default;

  display: flex !important; // need to override the display: block for .show
  flex-direction: row;

  background-color: map-get($background-colors, 'white');
  color: map-get($typography, 'primary');

  button {
    &.close {
      margin-right: 0.5rem;
      margin-top: 0.3rem;
      color: inherit !important;
    }
  }

  .toast-header {
    border: none;
    background-color: inherit !important;
  }

  .toast-body {
    background-color: inherit !important;
    padding: 1rem;
  }

  &.toast-success {
    background: map-get($primary-system-colors, 'success');
    border: $border-default-size map-get($primary-system-colors, 'success');
    color: map-get($typography, 'light');
  }

  &.toast-danger {
    background: map-get($primary-system-colors, 'danger');
    border: $border-default-size map-get($primary-system-colors, 'danger');
    color: map-get($typography, 'light');
  }

  &.toast-warning {
    background: map-get($primary-system-colors, 'warning');
    border: $border-default-size map-get($primary-system-colors, 'warning');
    color: map-get($typography, 'light');
  }

  &.toast-info {
    background: map-get($primary-system-colors, 'info');
    border: $border-default-size map-get($primary-system-colors, 'info');
    color: map-get($typography, 'light');
  }

  &.toast-success, &.toast-danger, &.toast-warning, &.toast-info {
    .close {
      color: inherit !important;
    }
  }
}