//  This is a custom fork of Podtable (https://github.com/inlogicstudio/podtable)
//
//  It is customized to work more closely with our custom implementations of: 
//  - a blazor data grid (see Grid.razor.cs in the client project).
//

// podtable
// 
#podtable-container {
  position: relative;
  overflow-x: hidden;
  padding: 0px;
  width: 100%;
}

.pt-object {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

#podtable-container table {
  width: 100%;
}

#podtable-container table.show-toggle .toggle::before,
#podtable-container table.show-toggle .main-toggle::before {
  content: '';
  position: absolute !important;
  display: block;
  text-indent: 0 !important;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  color: #666;
  width: 1.075rem;
  height: 1.075rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-size: 0.65rem;
  background-repeat: no-repeat;
  background-position: center center;
  transition: transform 0.1s ease-in-out;
  right: 0.5rem;
}

#podtable-container table.show-toggle .toggle,
#podtable-container table.show-toggle .main-toggle {
  padding-right: 20px;
  cursor: pointer;
}

#podtable-container table.show-toggle .toggle::before {
  //margin-top: -0.5rem;
}

#podtable-container table.show-toggle .main-toggle::before {
  margin-top: -1.15rem;
}

#podtable-container table.show-toggle .has-child .toggle::before,
#podtable-container table.show-toggle .main-toggle.expanded::before {
  transform: rotate(180deg);
}

#podtable-container table .hidden {
  display: none;
}

#podtable-container table tbody tr[data-ptr-ignore] {
  color: #606161;
  background-color: #ececec;
}

#podtable-container table .child .child-grid-row {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  row-gap: 15px;
  grid-row-gap: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 576px) {
  #podtable-container table .child .child-grid-row {
    grid-template-columns: repeat(3, auto);
  }
}

// set the column header to bold
#podtable-container table .child .child-grid-row .child-grid-col .col-header-content {
  font-weight: 700;
}

#podtable-container table .has-child {
  background-color: map-get($gray, 50);
}

#podtable-container table .has-child td {
  border-bottom: 0px;
}

#podtable-container table .child {
  border-bottom: $border-default;
  background-color: map-get($gray, 50);
}

#podtable-container table th.main-toggle {
  border-left: none !important;;
}

// hide the sort icon when it is displayed in the child row, only true headers are sortable.
#podtable-container table tr.child .dataGrid_column_sort_icon {
  display: none;
}