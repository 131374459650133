$selectedBackground: linear-gradient(map-get($default-green, 700), map-get($default-green, 700));

.slim-select-container.form-control {
  padding: 0;
  border: none;
}

.ss-main {
    color: $input-color;
    padding: 0;
    background-clip: unset;
    border: 1px solid $input-border-color;
    border-radius: 0.25em;

    @include font-size($input-font-size);
    @include font-weight("regular");
    line-height: $input-line-height;
    height: auto;
    display: flex;

    &.ss-disabled {
        background-color: $disabled-gray;
    }

    .ss-single-selected {
        outline: none;
        background-color: $input-bg;
        border: none;
        //height: 28px !important;
        height: 2rem !important;
        padding: .25rem .75rem !important;

        .placeholder {
            .ss-disabled {
                color: #60666d;
                //@include font-weight("regular");
                font-weight: 600; // matches label style
            }
        }

        .ss-open-below,
        .ss-open-above {
            border: 2px solid map-get($default-green, 500);
        }
    }
    // multi-select chip styling
    .ss-values {
        padding-left: 5px;
        padding-right: 5px;

        .ss-value {
            background: $selectedBackground;
            margin: 3px 0;

            .ss-value-delete {
                border: none;
                height: 100%;
                margin-right: 5px;
                @include fas-times();
                // hide default svg added by library
                svg {
                    display: none;
                }
            }
        }
    }
}

.ss-content .ss-list .ss-option.ss-highlighted,
.ss-content .ss-option.ss-selected {
  background: $selectedBackground;
  //background-color: map-get($default-green, 700);
  color: $white !important;
}

.ss-content .ss-list .ss-option {
  padding: .25rem .75rem;
  font-weight: 600; // matches the label style
}

.ss-content .ss-option.ss-option-selected:hover,
.ss-content .ss-list .ss-option:hover {
  background: map-get($gray, 200) !important;
  background-color: map-get($gray, 200) !important;
  color: map-get($gray, 900) !important;
}

.ss-content .ss-search input:focus {
  box-shadow: 0 0 5px map-get($default-green, 500);
}